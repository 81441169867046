<template>
  <div v-if="form">
    <div class="d-flex align-center">
      <user-photo 
        v-if="sender" 
        :id="sender" 
        photoSize="thumb"
        class="mr-2"
        :size="40"
        rounded
      />

      <v-icon v-else class="mr-2">mdi-account-circle-outline</v-icon>
      
      <div>
        <div class="font-weight-bold" :style="{ lineHeight: '1.2' }">{{ sender ? $store.getters['users/employee'](sender).fullName : 'Default sender' }} </div>
        <div 
          v-if="sender && $store.getters['users/employee'](sender).organization && $store.getters['users/employee'](sender).employeeRole"
          class="caption"
        >{{ $store.getters['users/employee'](sender).employeeRole }}</div>
      </div>
      
      <v-btn @click="showDialog()" class="ml-2" icon small>
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <v-form ref="form">
      <v-textarea
        v-model="message"
        placeholder="Default message"
        :rules="[rules.required]"
        :style="{ lineHeight: '1.2 !important' }"
        class="mt-5"
        outlined
      ></v-textarea>
      
      <v-btn
        @click="validateForm()"
        :loading="$store.state.orderform.status.updating"
        color="primary"   
        small
      >Save</v-btn>
    </v-form>

    <employee-dialog 
      ref="employeeDialog" 
      @confirmed="selected"
    />
  </div>
</template>

<script>
import rules from '@/rules'
import EmployeeDialog from '../../users/components/EmployeeDialog.vue'

export default {
  props: {
    form: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      sender: null,
      message: null,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'form': {
      handler(form) {
        if (form && form.autoResponse) {
          this.sender = form.autoResponse.sender
          this.message = form.autoResponse.message
        }
      },
      immediate: true
    }
  },
  
  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: { 
    EmployeeDialog 
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.employeeDialog.showDialog()
    },

    selected(user) {
      this.sender = user
      this.$refs.employeeDialog.closeDialog()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        if (this.sender) {
          this.$store.dispatch('orderform/updateFormData', {
            form: this.form,
            field: 'autoResponse',
            value: {
              sender: this.sender,
              message: this.message,
            },
          })
        }
        else {
          this.$store.dispatch('showError', 'Please select a default sender.')
        }
      }
    }
  }
}
</script>