<template>
  <v-card class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Product Description</div>

      <v-form ref="form">
        <v-textarea
          v-model="form.description"
          @blur="validate()"
          hide-details
          outlined
          dense
        ></v-textarea>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * VALIDATE URL
   *----------------------------------------------------------------------------*/
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('orderform/updateFormData', {
          form: this.form,
          field: 'description',
          value: this.form.description
        })
      }
      else this.$store.dispatch('showError', 'Invalid URL')
    }
  }
}
</script>