<template>
  <v-card v-if="form" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Product Image</div>
      <render-image
        v-if="form.productImage"
        :image="form.productImage"
        folder="forms"
        @deleted="clearFormData({ form, field: 'productImage' })"
        action
      />

      <v-file-input
        v-if="!form.productImage"
        v-model="photo"
        append-icon="mdi-image"
        accept="image/*"
        prepend-icon=""
        class="mb-3"
        hide-details
        outlined
        dense
      ></v-file-input>

      <upload-progress />

      <v-btn
        @click="startUpload()"
        v-if="!formStatus.uploading && !form.productImage"
        :disabled="!photo"
        color="primary"
        outlined
        block
        small
      >Upload</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      photo: null,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'uploadProductPhoto',
      'clearFormData',
    ]),

    startUpload() {
      Promise.resolve(
        this.uploadProductPhoto({
          form: this.form,
          photo: this.photo
        })
      )
      .then(() => {
        this.photo = null
      })
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.file_upload.status,
      formStatus: state => state.orderform.status,
    })
  }
}
</script>