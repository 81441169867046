<template>
  <v-card class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Active Campaign Tag</div>

      <v-form ref="form">
        <v-text-field
          @blur="updateField()"
          v-model="form.acTag"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * VALIDATE URL
   *----------------------------------------------------------------------------*/
  methods: {
    updateField() {
      this.$store.dispatch('orderform/updateFormData', {
        form: this.form,
        field: 'acTag',
        value: this.form.acTag
      })
    }
  }
}
</script>