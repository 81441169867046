<template>
  <div class="mt-5">
    <div class="d-flex">
      <back-square url="OrderForms" :params="{ id: $route.params.id }" />

      <div class="flex-grow-1">
        <!-- HEADER -->
        <div class="d-flex">
          <div>
            <h5 class="font-weight-bold title">Order Form Builder</h5>
            <div class="caption d-flex align-center">
              <v-breadcrumbs
                v-show="!editingName"
                :items="breadcrumbs"
                class="pa-0"
              ></v-breadcrumbs>
              
              <v-text-field
                @blur="
                  updateName($event)
                  editingName = false
                "
                v-show="editingName"
                :value="form.name"
                max-width="350"
                hide-details
                width="100%"
                height="13"
                outlined
                dense
              >
                <template #append>
                  <v-btn @click="editingName = false" x-small icon>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>

              <v-btn v-show="!editingName" @click="editingName = true" class="ml-1" small icon>
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex gap-12 align-center">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn @click="confirmDelete()" v-on="on" color="red" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span class="caption">Delete form</span>
            </v-tooltip>
          </div>
        </div>

        <v-row no-gutters>
          <v-col cols="8">
            <div class="mt-5 mb-n3 d-flex gap-7 align-center">
              <v-btn
                @click="$router.push({ query: { view: 'editor' }})"
                :color="$route.query.view == 'editor' ? 'primary' : 'default'"
                depressed
                x-small
              >
                Editor
              </v-btn>
              
              <v-btn
                @click="$router.push({ query: { view: 'preview' }})"
                :color="$route.query.view == 'preview' ? 'primary' : 'default'"
                class="d-none"
                depressed
                x-small
              >
                Preview
              </v-btn>
              
              <v-btn
                @click="$router.push({ query: { view: 'email' }})"
                :color="$route.query.view == 'email' ? 'primary' : 'default'"
                depressed
                x-small
              >
                Auto Response
              </v-btn>

              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn 
                    @click="
                      $copyText(`${$window.origin}${$router.resolve({ name: 'OrderPage', query: { id: $route.params.id } }).href}`)
                      .then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))
                    " 
                    class="mr-2" 
                    v-on="on"
                    small
                    icon 
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Copy form URL</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>

        <!-- BUILDER VIEW -->
        <v-row v-show="$route.query.view == 'editor'">
          <!-- BUILDER -->
          <v-col cols="8">
            <v-card
              max-width="1060"
              class="mt-2 box-shadow"
              outlined
            >
              <v-card-text>
                <!-- FIELDS -->
                <preloader v-if="status.getting" message="Please wait..." />

                <v-alert class="mb-0" v-if="!fields.length && !status.getting" type="info" border="left" text>
                  Click a field type on the right to insert your first field.
                </v-alert>

                <template v-if="fields.length && !status.getting">
                  <draggable v-model="sortableFields">
                    <v-hover 
                      v-for="(field, i) in sortableFields"
                      :key="field.id"
                      v-slot="{ hover }"
                    >
                      <v-card 
                        :class="{'mb-5' : i !== (sortableFields.length - 1), 'box-shadow': hover }"
                        class="transition"
                        outlined
                      >
                        <select-product v-if="field.type == 'product'" :form="form" />
                        <v-card-text v-else>
                          <div class="d-flex align-center">
                            <v-icon style="cursor: -webkit-grab; cursor: grab;" class="mr-2">mdi-drag-vertical</v-icon>
                            
                            <div class="flex-grow-1">
                              <div class="d-flex align-center mb-3">
                                <div class="mr-5">
                                  <v-icon small left>{{ getTypeIcon(field.type) }}</v-icon>
                                  <span class="font-weight-bold primary--text">{{ startCase(field.type) }}</span>
                                </div>

                                <v-checkbox
                                  v-if="field.type !== 'header'"
                                  v-model="field.required"
                                  label="Required" 
                                  class="ma-0 pa-0"
                                  hide-details
                                  dense
                                ></v-checkbox>

                                <v-spacer></v-spacer>

                                <v-btn
                                  @click="confirmDeleteField(field)"
                                  small
                                  text
                                  icon
                                >
                                  <v-icon color="red" small>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                              <v-text-field
                                v-model="field.label"
                                label="Label"
                                class="mb-1"
                                hide-details
                                outlined
                                dense
                              ></v-text-field>
                              
                              <v-text-field
                                v-model="field.placeholder"
                                label="Placeholder"
                                class="mb-1"
                                hide-details
                                outlined
                                dense
                              ></v-text-field>
                              
                              <v-text-field
                                v-model="field.description"
                                label="Description"
                                hide-details
                                outlined
                                dense
                              ></v-text-field>

                              <v-combobox
                                v-if="['dropdown', 'radio', 'checkbox'].includes(field.type)"
                                v-model="field.options"
                                label="Options"
                                deletable-chips
                                class="mt-5"
                                hide-details
                                small-chips
                                multiple
                                outlined
                                dense
                              ></v-combobox>

                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </draggable>
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- FIELD TYPE SELECTION -->
          <v-col cols="4">
            <v-sheet class="mt-2" max-width="300" color="transparent">
              <div v-if="fields.length" class="mb-5">
                <v-btn
                  @click="updateFields(form)"
                  :loading="status.updating"
                  :disabled="status.adding"
                  class="custom-shadow"
                  color="white primary--text"
                  height="35"
                  small
                  block
                >Save Changes</v-btn>
              </div>
              
              <!-- SELECT A FIELD -->
              <form-fields @selected="fieldSelected" class="mb-2" />

              <template v-if="Object.keys(form).length">
                <!-- ASSIGNEE -->
                <Assignee :form="form" />
                
                <!-- DEFAULT FOLLOWERS -->
                <default-followers :form="form" />

                <!-- ORDER TYPE -->
                <order-type :form="form" />

                <!-- TERMS -->
                <terms-selector :form="form" />
                
                <!-- ORDER PRIORITY -->
                <OrderPriority :form="form" />
                
                <!-- PRODUCT IMAGE -->
                <product-image :form="form" />
                
                <!-- PRODUCT DESCRIPTION -->
                <ProductDescription :form="form" />
                
                <!-- PRODUCT URL -->
                <product-url :form="form" />
                
                <!-- WEBHOOK URL -->
                <webhook-url :form="form" />
                
                <!-- ACTIVE CAMPAIGN TAG -->
                <active-campaign-tag :form="form" />
              </template>
            </v-sheet>
          </v-col>
        </v-row>

        <!-- FORM PREVIEW -->
        <v-card
          v-show="$route.query.view == 'preview'"
          class="mt-5 box-shadow"
          max-width="1060"
          outlined
        >
          <v-card-text class="pa-10">
            <Form :fields="sortableFields" preview/>
          </v-card-text>
        </v-card>

        <!-- EMAIL NOTIFICATIONS -->
        <v-card
          v-show="$route.query.view == 'email'"
          class="mt-5 box-shadow"
          max-width="1060"
          outlined
        >
          <v-card-text class="pa-10">
            <auto-response :form="form" />
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- DELETE FIELD -->
    <confirm-delete
      :show="deleteFieldDialog"
      message="Are you sure you want to delete this field?"
      :deleting="status.deleting"
      @cancel="deleteFieldDialog = false"
      @confirmed="deleteConfirmed()"
    />

    <!-- DELETE FORM -->
    <confirm-delete 
      :show="deleteFormDialog"
      message="Delete this form?"
      :deleting="status.deleting"
      @cancel="closeDeleteFormDialog()"
      @confirmed="deleteFormConfirmed()"
    />

  </div>
</template>

<script>
import startCase from 'lodash/startCase'
import { mapState, mapActions } from 'vuex'

// COMPONENTS
import Form from './Form'
import Assignee from './Assignee'
import OrderType from './OrderType'
import draggable from 'vuedraggable'
import ProductUrl from './ProductUrl'
import ProductDescription from './ProductDescription'
import WebhookUrl from './WebhookUrl'
import FormFields from './FormFields'
import AutoResponse from './AutoResponse'
import ProductImage from './ProductImage'
import TermsSelector from './TermsSelector'
import SelectProduct from './SelectProduct'
import OrderPriority from './OrderPriority'
import DefaultFollowers from './DefaultFollowers'
import ActiveCampaignTag from './ActiveCampaignTag'

export default {
  metaInfo: {
    title: 'Order Form Builder'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      startCase,
      type: null,
      toDelete: null,
      mode: 'editor',
      checked: false,
      editingName: false,
      createDialog: false,
      fieldsDialog: false,
      deleteFieldDialog: false,
      deleteFormDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.orderform.status,
      fields: state => state.orderform.fields,
    }),

    form: function () {
      return this.$store.getters['orderform/form'](this.$route.params.id)
    },

    sortableFields: {
      get() {
        return this.fields
      },

      set(newVal) {
        this.$store.commit('orderform/updateFields', newVal)
      }
    },

    breadcrumbs: function () {
      return [
        {
          text: 'Forms',
          to: { name: 'OrderForms' },
          disabled: false,
          link: true,
          exact: true,
        },
        {
          text: this.form.name,
          to: { name: 'CreteOrderForm', params: { id: this.$route.params.id } },
          exact: true,
          disabled: true,
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'form': {
      handler(form) {
        if (Object.keys(form).length && !this.checked) {
          this.checked = true
          this.getFields(form)
          this.checkProductField(form)
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Form,
    Assignee,
    draggable,
    OrderType,
    FormFields,
    ProductUrl,
    WebhookUrl,
    ProductImage,
    AutoResponse,
    TermsSelector,
    SelectProduct,
    OrderPriority,
    DefaultFollowers,
    ActiveCampaignTag,
    ProductDescription,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orderform', [
      'checkProductField',
      'updateFormField',
      'updateFields',
      'deleteField',
      'deleteForm',
      'getFields',
      'addField',
      'getForm',
    ]),

    showAddProductDialog() {
      this.createDialog = true
    },

    closeDeleteFormDialog() {
      this.deleteFormDialog = false
    },

    confirmDelete() {
      this.deleteFormDialog = true
    },

    closeDialog() {
      this.createDialog = false
    },

    deleteFormConfirmed() {
      Promise.resolve(this.deleteForm(this.form))
      .then(() => {
        this.closeDeleteFormDialog()
        this.$router.push({ name: 'OrderForms' })
      })
    },

    closeFieldsDialog() {
      this.fieldsDialog = false
    },

    fieldSelected(e) {
      this.closeFieldsDialog()
      this.addField({
        form: this.form,
        type: e
      })
    },

    confirmDeleteField(field) {
      this.toDelete = field
      this.deleteFieldDialog = true
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteField(this.toDelete))
      .then(() => {
        this.toDelete = null
        this.deleteFieldDialog = false
      })
    },

    getTypeIcon(type) {
      let types = {
        'attachment': 'mdi-attachment',
        'textarea': 'mdi-form-textarea',
        'dropdown': 'mdi-form-dropdown',
        'text_field': 'mdi-form-textbox',
        'checkbox': 'mdi-checkbox-marked-outline',
        'header': 'mdi-format-text-variant-outline',
        'radio': 'mdi-checkbox-marked-circle-outline',
      }

      return types[type]
    },

    updateName(e) {
      if (e.target.value !== this.form.name) {
        this.updateFormField({
          field: 'name',
          form: this.form,
          value: e.target.value,
          message: 'Form name updated',
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getForm(this.$route.params.id)
  }
}
</script>