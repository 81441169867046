<template>
  <v-card-text v-if="form" class="d-flex align-center">
    <v-icon style="cursor: -webkit-grab; cursor: grab;" class="mr-2">mdi-drag-vertical</v-icon>

    <div class="flex-grow-1">
      <div class="font-weight-bold primary--text mb-2">
        <v-icon small left>mdi-post-outline</v-icon>
        Select Products
      </div>

      <v-text-field
        v-model="form.productName"
        label="Label"
        class="mb-2"
        hide-details
        outlined
        dense
      ></v-text-field>

      <v-select
        :item-text="(item) => `${item.description} ($${$options.filters.amountNoneDecimal(item.unit_amount)})`"
        v-model="form.prices"
        v-if="prices.length"
        :items="prices"
        deletable-chips
        item-value="id"
        hide-details
        small-chips
        multiple
        outlined
        chips
        dense
      ></v-select>
    </div>
  </v-card-text>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    form: Object,
  },
  
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      prices: state => state.products.prices
    }),
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('products', [
      'getPrices'
    ]),
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getPrices()
  }
}
</script>
