<template>
   <v-card v-if="form" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Priority</div>

      <v-radio-group 
        @change="updatePriority()" 
        v-model="form.priority"
        class="mt-0"
        hide-details
        dense 
        row
      >
        <v-radio 
          v-for="(prio, i) in ['low', 'medium', 'high']"
          :label="$options.filters.startCase(prio)" 
          :value="prio"
          :key="i"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
   </v-card>
</template>

<script>
export default {
  props: { form: Object },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    updatePriority() {
      this.$store.dispatch('orderform/updateFormField', {
        form: this.form,
        field: 'priority',
        value: this.form.priority,
      })
    }
  }
}
</script>