<template>
  <v-card class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Google Chat Webhook URL</div>

      <v-form ref="form">
        <v-text-field
          v-model="form.webhook"
          @change="validateUrl()"
          :rules="[rules.url]"
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
    }
  },

  /*------------------------------------------------------------------------------
   * VALIDATE URL
   *----------------------------------------------------------------------------*/
  methods: {
    validateUrl() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('orderform/updateFormData', {
          form: this.form,
          field: 'webhook',
          value: this.form.webhook
        })
      }
      else this.$store.dispatch('showError', 'Invalid URL')
    }
  }
}
</script>