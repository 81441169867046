<template>
  <v-card class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text">Order Type</div>

      <v-radio-group 
        v-if="types.length"
        v-model="selected" 
        @change="valueUpdated"
        class="mt-2"
      >
        <div 
          v-for="type in orderBy(types, 'name', 'asc')"
          :key="type.id"
          class="d-flex align-center"
        >
          <v-radio
            :label="type.name"
            :value="type.id"
            append-icon="mdi-delete"
            hide-details
            multiple
            dense
          />
          <v-spacer></v-spacer>
          <v-menu offset-y left>
            <template #activator="{ on }">
              <v-btn v-on="on" small icon>
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="$store.commit('order_types/setData', type)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="showDeleteDialog(type)">
                <v-list-item-title class="error--text">Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-radio-group>

      <v-form ref="form" @submit.prevent="validateForm()">
        <v-text-field
          v-model="data.name"
          :rules="[rules.required]"
          hide-details
          outlined
          dense
        >
          <template #append>
            <v-btn
              @click="validateForm()"
              :loading="status.saving"
              color="primary"
              class="mb-2"
              small
              icon
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-form>
    </v-card-text>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      selected: null,
      deleteDialog: null,
      deleteMessage: null,
      toDelete: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.order_types.data,
      types: state => state.order_types.types,
      status: state => state.order_types.status,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'form': {
      handler(value) {
        if (Object.keys(value).length)
          this.selected = value.orderType
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('order_types', [
      'deleteOrderType',
      'saveType',
      'getTypes',
    ]),
    
    ...mapActions('orderform', [
      'updateOrderType',
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.saveType())
        .then(() => {
          this.$store.commit('order_types/setData', {})
        })
      }
    },

    showDeleteDialog(type) {
      this.toDelete = type
      this.deleteMessage = `Delete type ${type.name}?`
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteOrderType(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    valueUpdated(value) {
      this.updateOrderType({
        type: value,
        form: this.form
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getTypes()
  }
}
</script>