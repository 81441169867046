<template>
  <v-form v-if="form" ref="form">
    <div
      v-for="field in fields"
      :key="field.id"
    >
      <!-- IF TEXT FIELD -->
      <div v-if="field.type == 'text_field'">
        <div :class="labelClass">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </div>
        <v-text-field
          :rules="field.required ? [rules.required] : []"
          :placeholder="field.placeholder || ''"
          v-model="field.value"
          outlined
          dense
        ></v-text-field>
      </div>
      
      <!-- IF TEXT FIELD -->
      <div v-if="field.type == 'textarea'">
        <div :class="labelClass">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </div>
        <v-textarea
          :rules="field.required ? [rules.required] : []"
          :placeholder="field.placeholder || ''"
          v-model="field.value"
          outlined
          dense
        ></v-textarea>
      </div>
      
      <!-- IF DROPDOWN -->
      <div v-if="field.type == 'dropdown'">
        <div :class="labelClass">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </div>
        <v-select
          :rules="field.required ? [rules.required] : []"
          :placeholder="field.placeholder || ''"
          :clearable="!field.required"
          :items="field.options"
          v-model="field.value"
          outlined
          dense
        ></v-select>
      </div>
      
      <!-- IF PRODUCT -->
      <div v-if="field.type == 'product'">
        <div :class="labelClass">
          {{ form.productName }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </div>
        <v-select
          :items="form.prices.map(id => $store.getters['products/price'](id))"
          :rules="[rules.required]"
          item-text="description"
          v-model="data.price"
          item-value="id"
          outlined
          dense
        ></v-select>
      </div>
      
      <!-- IF CHECKBOX -->
      <div v-if="field.type == 'checkbox'">
        <div :class="labelClass">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </div>

        <div class="mb-5 d-flex gap-12 align-center">
          <v-radio-group 
            :rules="field.required ? [rules.required] : []"
            v-model="field.value"
            class="mt-0"
            row
          >
            <v-checkbox
              v-for="(option, i) in field.options"
              v-model="field.value"
              class="ma-0 mr-6"
              :label="option"
              :value="option"
              color="primary"
              hide-details
              :key="i"
              dense
            ></v-checkbox>
          </v-radio-group>
        </div>
      </div>
      
      <!-- IF RADIO -->
      <div v-if="field.type == 'radio'">
        <p :class="labelClass">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </p>
        <div class="mb-4">
          <v-radio-group
            v-model="field.value"
            :rules="field.required ? [rules.required] : []"
            row
          >
            <v-radio
              v-for="(option, i) in field.options"
              class="ma-0 mr-6"
              :label="option"
              :value="option"
              color="primary"
              hide-details
              :key="i"
              dense
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      
      <!-- IF HEADER -->
      <div v-if="field.type == 'header'">
        <h6 class="font-weight-bold" style="font-size: 18px !important">
          {{ field.label }}
          <span v-if="field.description" style="font-size: 15px !important" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </h6>
        <v-divider class="my-6"></v-divider>
      </div>

      <!-- IF ATTACHMENT -->
      <div v-if="field.type == 'attachment'">
        <p class="font-weight-bold dark--text">
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <span v-if="field.description" class="font-weight-medium grey--text"><br>{{ field.description }}</span>
        </p>

        <v-scale-transition
          v-if="field.value && field.value.length"
          class="d-flex flex-wrap gap-12 mb-5"
          tag="div"
          group
        >
          <file-block
            v-for="(attachment, i) in field.value"
            :key="i"
            :file="attachment"
            @remove="removeFile(field, i)"
          />
        </v-scale-transition>

        <v-file-input
          v-model="field.value"
          :rules="field.required ? [rules.required] : []"
          prepend-inner-icon="mdi-upload"
          prepend-icon=""
          multiple
          outlined
        ></v-file-input>
      </div>
    </div>

    <!-- BUTTON -->
    <div v-if="preview" class="d-flex gap-7">
      <v-btn @click="validateForm" color="primary">Test Submit</v-btn>
      <v-btn @click="resetForm" color="primary" outlined>Reset</v-btn>
    </div>

    <v-card v-if="submitted" class="mt-5" dark>
      <v-card-text>
        <div 
          class="mb-3" 
          v-for="(field, i) in fields" :key="i"
          :class="{ 'd-none' : field.type == 'header' }"
        >
          <pre class="font-weight-bold accent--text">{{ field.label }}</pre>
          <pre class="d-inline-block text-pre-wrap">{{ formatValue(field.value) }}</pre>
        </div>
      </v-card-text>
    </v-card>

    <template>
      <div class="caption font-weight-bold mb-2">Total Setup Cost</div>
      <div class="text-h6 success--text font-weight-bold mb-3">
        $ {{ price.unit_amount || 0 | amountNoneDecimal }}
      </div>
    </template>

    <!-- TERMS -->
      
    <div class="mb-5" v-if="form && form.terms && form.terms.length">
      <div
        v-for="term in form.terms"
        :key="term"
        class="d-flex align-center"
      >
        <v-checkbox
          class="mr-1 ma-0 pa-0"
          :true-value="true"
          :rules="[rules.required]"
          hide-details
          dense
        ></v-checkbox>

        <div class="caption">
          <span class="mr-1">I have read, understand and accept the Lightning Sites</span>
          <a @click.prevent="showTermPreview(term)" href="#">{{ $store.getters['order_term/term'](term).title }}</a>
        </div>
      </div>
    </div>

    <!-- PREVIEW DIALOG -->
    <preview-term :id="term" ref="termDialog" />
  </v-form>
</template>

<script>
import rules from '@/rules'
import _join from 'lodash/join'
import { mapState } from 'vuex'
import pluralize from 'pluralize'
import camelCase from 'lodash/camelCase'
import FileBlock from './FileBlockSimple'
import PreviewTerm from './PreviewTermDialog'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      pluralize,
      camelCase,
      term: null,
      result: [],
      submitted: false,
      labelClass: 'font-weight-bold secondary--text caption mb-2 heading-font'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.orders.data
    }),

    price: function () {
      return this.data ? this.$store.getters['products/price'](this.data.price) : null
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    form: Object,
    fields: Array,
    preview: Boolean,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FileBlock,
    PreviewTerm
  },

  /*------------------------------------------------------------------------------
   * METHODS`
   *----------------------------------------------------------------------------*/
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        this.submitted = true
      }
      else {
        console.log('form has error')
      }
    },

    resetForm() {
      this.$refs.form.reset()
    },

    formatValue(value) {
      if (typeof value == 'object')
        return _join(value, '\n')
      else
        return value
    },

    getFileName(file) {
      return file.name.length > 20 ? `${file.name.substr(0, 17)}...` : file.name
    },

    removeFile(field, i) {
      field.value.splice(i, 1)
    },

    getFileSize(file) {
      return file.size
    },

    showTermPreview(term) {
      this.term = term
      this.$refs.termDialog.dialog = true
    }
  }
}
</script>