<template>
  <v-card class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text">Select Terms</div>

      <template v-if="terms.length">
        <v-checkbox
          v-model="datas.termsData"
          @change="updateFormTerms(form)"
          v-for="term in terms"
          :key="term.id"
          :label="term.title"
          :value="term.id"
          hide-details
          multiple
          dense
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.order_term.status,
      datas: state => state.orderform.datas,
    }),

    terms: function () {
      return this.$store.getters['order_term/publishedTerms']
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'form': {
      handler(value) {
        if (Object.keys(value).length)
          if (value.terms) this.$store.commit('orderform/updateDatas', { termsData: value.terms })
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('order_term', [
      'getTerms',
    ]),
    
    ...mapActions('orderform', [
      'updateFormTerms',
    ]),
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getTerms(true)
  }
}
</script>